import React, { useState, useEffect } from 'react';
import { Button } from "react-bootstrap"
import {
  useSignMessage,
  useAccount,
  useDisconnect,
  useBalance,
  useWalletClient,
  useNetwork,
  useConnect
} from 'wagmi';
import { countResult } from '../helper';

const Web3 = (props) => {

  let etherscanUrl = "";

  const { transactionError, transactionData, transactionIsSuccess, to, amount, cryptosList, isSupportedCrypto, chain, allBalance } = props;

  const [message, setMessage] = useState(
    "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s."
  );
  const [isShowTimer, setIsShowTimer] = useState(false);
  const { data: signData, error, signMessage, signMessageAsync, isLoading: isLoadingSignMessage, isIdle, status: messageStatus } = useSignMessage();
  const { isConnected, address, status, addresses } = useAccount();
  const { data: walletClient, isError: isErrorWalletClient, isLoading: isLoadingWalletClient } = useWalletClient()
  const { data: balanceData, isError, isLoading: isLoadingBalance } = useBalance({ address: address ? address : "", })
  const { disconnect } = useDisconnect();
  const { connect, connectors, error: errorConnect, isLoading: isLoadingConnect, pendingConnector } = useConnect();

  // useEffect(() => {
  //   if(isConnected){
  //     setTimeout(() => {
  //       signMessage({ message: message })
  //     }, 1000);
  //   }
  // }, [isConnected])

  if (typeof transactionData === "object" && transactionData.hash && transactionData.hash.length) {
    etherscanUrl = `https://etherscan.io/tx/${transactionData?.hash}`
  } else {
    etherscanUrl = `https://etherscan.io/tx/${transactionData}`
  } 

  return (
    <>
      {address && <p className='word-break-break-word mb-1 mt-3'><b>Address: </b>{address}</p>}
      {
        balanceData &&
        <div>
            {allBalance && chain &&
              <p className='word-break-break-word mb-1 mt-3'><b>Network balance: </b>{(allBalance.result / countResult(chain.nativeCurrency.decimals)).toFixed(5)} {balanceData?.symbol}</p>
            }
          <p className='mb-1'><b>Balance: </b>{Number(balanceData?.formatted).toFixed(5)} {balanceData?.symbol}</p>
          <p className='mb-1'><b>Crypto: </b>{balanceData.symbol}</p>
          <p className='mb-1'><b>Decimals: </b>{balanceData.decimals}</p>
          {status && <p className='mb-1 capitalize'><b>Status: </b>{status}</p>}
          {signData && <p className='word-break-break-word mb-1'><b>Signature: </b>{signData}</p>}
        </div>
      }
      {chain && <p className='mb-1'><b>Connected to: </b>{chain.name} </p>}
      {transactionIsSuccess && (
        <div className='text-white'>
          Successfully sent {amount} ether to {to}
          <div>
            <a className='text-white' href={`${etherscanUrl}`} target='_blank'>Etherscan</a>
          </div>
        </div>
      )}
      {/* {error && <div className="mt-2 text-danger transaction-error-message-block">User rejected the "Sign" request</div>} */}
      {/* {transactionError && <div className="mt-2 text-danger transaction-error-message-block">{JSON.stringify(transactionError)}</div>} */}
      {
        !isSupportedCrypto && chain && chain.nativeCurrency && chain.nativeCurrency.symbol &&
        <div className="mt-2 text-danger transaction-error-message-block">
          <p className='mb-0'><b>{chain.nativeCurrency.symbol}</b> not supported by our platform. Please, change your network.</p>
        </div>
      }
    </>

  );
}

export default Web3
