import React, { useEffect, useState, memo } from "react"
import { useWalletClient, useNetwork } from "wagmi"
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { BiTimer } from "react-icons/bi"
import MainService from "../../../../Services/MainService"

const MainPrice = memo((props) => {

    const { paymentData, tick, price, selectedWallet, selectedCrypto, organizationData, chain } = props;
    const { data: walletClient, isError: isErrorWalletClient, isLoading: isLoadingWalletClient, error: errorWalletClient } = useWalletClient();

    return <div className="cx-window-left-side">
        <div className="cx-window-body-container">
            {
                paymentData ?
                    <CustomComponent paymentData={paymentData} />
                    : null
            }
            {
                <div className="cx-company-name-wrapper">
                    {
                        organizationData && organizationData.logoImage && organizationData.logoImage ?
                            <img src={organizationData.logoImage} alt="Organization logo" />
                            : null
                    }
                    {
                        organizationData && organizationData.organizationName && organizationData.organizationName ?
                            <p style={{ marginLeft: organizationData.logoImage ? "20px" : 0 }}>{organizationData.organizationName}</p>
                            : null
                    }
                </div>
            }
            <div className="cx-order-top-info">
                <ul className="cx-order-top-info-list">
                    <li className="cx-order-top-info-item">Order:&nbsp;
                        <span id="inf_orderNum">
                            {paymentData && paymentData.remoteTransactionId ? paymentData.remoteTransactionId : ""}
                        </span>
                    </li>
                    <li className="cx-order-top-info-item">Wallet:&nbsp; <span id="inf_wallet">
                        {
                            selectedWallet ?
                                <React.Fragment>
                                    {MainService.getCoinImage(selectedWallet?.name)}
                                    {selectedWallet.name}
                                </React.Fragment>
                                : "Not set"
                        }

                    </span>
                    </li>
                    <li className="cx-order-top-info-item">Crypto:&nbsp; <span id="inf_crypto">
                        {
                            selectedCrypto ?
                                <React.Fragment key={selectedCrypto.id}>
                                    {MainService.getCoinImage(selectedCrypto.name)}
                                    <span className="ms-2">{selectedCrypto.name}</span>
                                </React.Fragment>
                                : chain && chain.nativeCurrency && chain.nativeCurrency.symbol ? chain.nativeCurrency.symbol : "Not set"
                        }
                    </span>
                    </li>
                </ul>
            </div>
            <div className="cx-window-body">
                <hr />
                <p className="cx-paymant-description">
                    {paymentData && paymentData.transactionText ? paymentData.transactionText : ""}
                </p>

                <div className="cx-order-info">
                    <ul className="cx-order-info-list">
                        <li className="order-info-list-item">
                            <span className="cx-order-list-item-title">Total price</span>
                            <span
                                className="cx-order-list-item-value inf_fiatPrice"
                                id="inf_fiatPrice">
                                {paymentData && paymentData.fiatAmount ? `$ ${paymentData.fiatAmount}` : ""}
                            </span>
                        </li>
                        <li className="order-info-list-item">
                            <span className="cx-order-list-item-title">Exchange rate</span>
                            <span className="cx-order-list-item-value">
                                {
                                    tick ?
                                        <span id="currenttick">$ {tick.toFixed(2)}</span>
                                        : null
                                }
                            </span>
                        </li>
                        <li className="order-info-list-item">
                            <span className="cx-order-list-item-title">Total</span>
                            <span className="cx-order-list-item-value">
                                {
                                    selectedCrypto ?
                                        <div className="small-icon">{MainService.getCoinImage(selectedCrypto?.name)}</div>
                                        : null
                                }
                                {
                                    price ?
                                        <span className="totalcost ms-2">{price}</span>
                                        : null
                                }
                                {
                                    selectedCrypto ?
                                        <span className="totalcost ms-2">{selectedCrypto?.name}</span>
                                        : null
                                }
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="cx-orders-list-container">
                    <hr />
                    <strong>Payment information</strong>
                    <div className="cx-orders-container">
                        {
                            paymentData && paymentData.paymentProductInfo && paymentData.paymentProductInfo.length ?
                                paymentData.paymentProductInfo.map((el, index) => {
                                    return <div key={index} className="cx-order-wrapper">
                                        <img src={el.imageUrl} alt={el.name} />
                                        {
                                            el.description ?
                                                <p className="cx-order-desction">{el.description}</p>
                                                : null
                                        }
                                        <div className="cx-order-price">
                                            {
                                                el.cost ?
                                                    <strong>
                                                        <span>$</span>{el.cost}
                                                    </strong>
                                                    : null
                                            }
                                        </div>
                                    </div>
                                })
                                : null
                        }
                    </div>
                </div>
            </div>
        </div>
    </div>
})

function CustomComponent({ paymentData }) {
    const [countdown, setCountdown] = useState('');
    const [isFinished, setIsFinished] = useState(false);

    useEffect(() => {
        if (!paymentData?.paymentPageOpenedDeadline) return false;
        const paymentPageOpenedDeadline = paymentData?.paymentPageOpenedDeadline;
        const targetDate = new Date(paymentPageOpenedDeadline.includes("Z") ? paymentPageOpenedDeadline : paymentPageOpenedDeadline + 'Z');
        const countdownInterval = setInterval(() => {
            const currentDate = new Date();
            const remainingTime = targetDate - currentDate;
            if (remainingTime <= 0) {
                clearInterval(countdownInterval);
                setIsFinished(true);
                setCountdown('Countdown has ended');
            } else {
                const minutes = Math.floor((remainingTime % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((remainingTime % (1000 * 60)) / 1000);
                const countdownString = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
                setCountdown(countdownString);
            }
        }, 1000);
        return () => {
            clearInterval(countdownInterval);
        };
    }, [paymentData?.paymentPageOpenedDeadline]);

    const redirectToCancelUrl = () => {
        window.location.href = paymentData.cancelUrl;
    }

    return (
        <>
            {
                isFinished ?
                    <Modal show={true} onHide={redirectToCancelUrl} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>Transaction expired</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Your transaction expired, please go to merchant and make a new one.</Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={redirectToCancelUrl}>Close</Button>
                        </Modal.Footer>
                    </Modal>
                    : null
            }
            {
                isFinished ?
                    <p className="bold text-danger">Transaction expired</p>
                    : <div className="mb-3 bold d-flex align-items-center">
                        <span>
                            Transaction will expire:&nbsp;
                        </span>
                        <BiTimer size={24} className='me-1' />
                        <span>
                            {countdown}
                        </span>
                    </div>
            }
        </>


    );
}

export default MainPrice