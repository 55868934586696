import axios from 'axios';
import { mainUrl } from '../Constants/ApiKeys.js';
import AlertService from './AlertService.js';

const api = axios.create({
  baseURL: mainUrl,
  withCredentials: true,
});


api.interceptors.request.use(async config => {
  config.headers = {
    'token': localStorage.getItem("token") ? localStorage.getItem("token") : {},
    // 'Content-Type': 'application/json; charset=utf-8;',
  };

  return config;
});

api.interceptors.response.use(response => {
  if (response.data) return response.data;
}, error => {
  if (error.response) {
    if (error.response.status === 404) { // Status Code: 404
      return Promise.reject("404 Not Found");
    } else if (error.response.status === 500) { // Status Code: 500
      return Promise.reject("500 Internal Server Error");
    }
    if (typeof error.response.data === "object") {
      const currentError = { ...error.response.data };
      const errors = currentError.errors?.Error;
      let errorMessages = [];
      if (errors && errors.length > 0) {
        errors.forEach((error) => {
          const errorParams = error.params;
          if (errorParams) {
            let errorMsg = `${errorParams?.Description} ${errorParams?.ErrorNumber ? `(${errorParams?.ErrorNumber})` : ""}`
            errorMessages.push(errorMsg)
          }
          // const paramKeys = Object.keys(errorParams);
          // paramKeys.forEach((key) => {
          //   const value = errorParams[key];
          //   if (typeof value === 'string' && value.trim().length > 0) {
          //     errorMessages.push(value);
          //   }
          // });
        });
      } else {
        return Promise.reject("Unrecognized error");
      }
      if (errorMessages.length) {
        showError(errorMessages)
        return Promise.reject();
      } else {
        return Promise.reject("Unrecognized error");
      }
    } else {
      return Promise.reject("Unrecognized error");
    }

  } else {
    return Promise.reject("Unrecognized error");
  }
});

const showError = (errorMessages) => {
  // errorMessages.forEach(error => {
  //   AlertService.alert("error", error)
  // });

  const filteredNumbers = errorMessages.filter(item => !isNaN(item)); //error numbers

  const filteredStrings = errorMessages.filter(item => isNaN(item));
  filteredStrings.forEach(error => {
    AlertService.alert("error", error)
  });
}

class ApiService {

  static validateKyc(data) {
    return api.post(`/kyc/check`, data)
  };
  static verifyIsKycAlreadyDone() {
    return api.get(`/kyc-info`)
  };
  static getCryptos() {
    return api.get(`/api/crypto/list`)
  };
  static getWallets() {
    return api.get(`/api/wallet/list`)
  };
  static sendPhoneNumber(data) {
    return api.post(`/kyc`, data)
  };
  static selectCrypto(data) {
    // return api.post(`/api/payment/set-crypto`, data)
    return api.post(`/set-crypto`, data)
  };
  static selectWallet(data) {
    return api.post(`/set-wallet`, data)
  };
  static analyzePaymentData(token) {
    return api.get(`/get/${token}`)
  };
  static loadOrgData(organizationId) {
    return api.get(`/api/organization/paymentOrg/?orgId=${organizationId}`)
  };
  static pay() {
    return api.post(`/pay`)
  };
  static cancelPayment(token) {
    return api.post(`/cancel/${token}`)
  };
  static qrpay() {
    return api.get(`/qrpay`)
  };
  static paySuccess(data) {
    return api.post(`/hash`, data)
  };
  static payError(data) {
    return api.post(`/pay-error`, data)
  };
  static getAccountBalance(chainId, address) {
    return api.get(`/???`)
  };
  static getCountries() {
    return api.get(`/api/Country/GetAll`)
  };


}

export default ApiService;
