import React, { memo } from 'react'
import ReactSelectOption from '../../../../Components/SelectOptions/ReactSelectOption';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const KycVerification = memo(({
  isLoading,
  onChange,
  countries,
  onSelectCountry,
  isInvalidFirstName,
  checkFieldValidation,
  isInvalidLastName,
  isInvalidEmail,
  onPhoneNumberChange,
  sendPhoneNumber,
  isShowSMSwaitingInfoMsg,
  isTransactionValidated,
  isKycSent,
  values,
}) => {

  return (
    <div id="kycVerification">
      <p>Please enter your valid credentials to continue. <br /><small>All fields are required.</small></p>
      <div className="d-lg-flex form-group">
        <input
          id="firstName"
          type="text"
          className={`
              form-control ps-3 mb-1 me-0 me-lg-1
              ${isLoading ? "disabled" : ""}
              ${isInvalidFirstName ? "invalid-field" : values.firstName.trim().length ? "valid-field" : ""}
            `}
          placeholder="First name*"
          value={values.firstName || ""}
          required
          onChange={(event) => onChange(event, "firstName")}
          onBlur={() => checkFieldValidation(values.firstName, "firstName")}
        />
        <input
          id="lastName"
          type="text"
          className={`
              form-control ps-3 mb-1 ms-0
              ${isLoading ? "disabled" : ""}
              ${isInvalidLastName ? "invalid-field" : values.lastName.trim().length ? "valid-field" : ""}
            `}
          placeholder="Last name*"
          value={values.lastName || ""}
          required
          onChange={(event) => onChange(event, "lastName")}
          onBlur={() => checkFieldValidation(values.lastName, "lastName")}
        />
      </div>
      <div className="mb-1">
        <input
          id="email"
          type="email"
          className={`
              form-control ps-3
              ${isLoading ? "disabled" : ""}
               ${isInvalidEmail ? "invalid-field" : values.email.trim().length ? "valid-field" : ""}
            `}
          placeholder="Email*"
          value={values.email || ""}
          onChange={(event) => onChange(event, "email")}
          onBlur={() => checkFieldValidation(values.email, "email")}
        />
      </div>
      {
        countries && countries.length ?
          <div className="mb-1" style={{ paddingRight: "1px", paddingLeft: "1px" }}>
            <ReactSelectOption
              value={values.selectedCountryId}
              className={`vnd--select ${values.selectedCountryId ? "valid-field" : ""} ${isLoading ? "disabled" : ""}`}
              isSearchable={true}
              placeholder="Select your country*"
              selectedValue={(() => {
                const selectedValue = { ...countries.find(data => data.id === values.selectedCountryId) };
                if (Object.keys(selectedValue).length !== 0) {
                  selectedValue.label = selectedValue.name;
                  selectedValue.value = selectedValue.id;
                  return selectedValue;
                }
              })()}
              items={countries.map(data => ({ label: data.name, value: data.id }))}
              onChange={(item) => onSelectCountry(item.value, "selectedCountryId")}
            />
          </div>
          : null
      }
      <div className="mb-1">
        <input
          id="residentialAddress"
          type="text"
          className={`
             form-control ps-3
             ${values.residentialAddress.trim().length ? "valid-field" : ""}
             ${isLoading ? "disabled" : ""}
            `}
          placeholder="Residential address*"
          value={values.residentialAddress}
          required
          onChange={(event) => onChange(event, "residentialAddress")}
        />
      </div>
      <div className="cx-phone-number-block mb-1">
        {
          countries && countries.length ?
            <PhoneInput
              country={values.selectedCountryId ? countries.find(item => item.id === values.selectedCountryId)?.isoCode2.toLowerCase() : ""}
              value={values.phoneNumber}
              className={`custom-phone-number-input-block ${isLoading ? " disabled" : ""}`}
              onChange={(event) => onPhoneNumberChange(event, "phoneNumber")}
            />
            : null
        }
      </div>
      <div className="d-lg-flex form-group justify-content-end">
        <input
          className={`
                cx-number-btn mt-2 mx-0
                ${!values.phoneNumber ||
              isInvalidFirstName ||
              isInvalidLastName ||
              isInvalidEmail ||
              isLoading ||
              !values.selectedCountryId ||
              !values.firstName.trim().length ||
              !values.lastName.trim().length ||
              !values.email.trim().length ||
              !values.residentialAddress.trim().length ||
              isTransactionValidated || isKycSent ?
              "disabled" :
              ""}`
          }
          type="button"
          id="send_kyc"
          value="send"
          onClick={sendPhoneNumber}
        />
      </div>
      {
        isShowSMSwaitingInfoMsg ?
          <sub>Please wait</sub>
          : null
      }
      <div className="invalid-feedback">Please type valid number.</div>
    </div>
  )
})

export default KycVerification;