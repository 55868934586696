import React, { useEffect, useState } from 'react'
import * as signalR from '@microsoft/signalr'
import Footer from './modules/Footer'
import Main from './modules/main/Main'
import { useDispatch, useSelector } from 'react-redux'
import { setToken } from './Store/Actions/main'
import PageSpinner from './Components/Spinners/pageSpinner'
import { mainUrl } from './Constants/ApiKeys'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



import { createWeb3Modal, useWeb3ModalEvents } from '@web3modal/wagmi/react'
import { defaultWagmiConfig } from '@web3modal/wagmi/react/config'
import { WagmiProvider } from 'wagmi'
// import {
//   acala,
//   arbitrum,
//   arbitrumGoerli,
//   arbitrumNova,
//   astar,
//   arbitrumSepolia,
//   astarZkatana,
//   aurora,
//   auroraTestnet,
//   avalanche,
//   avalancheFuji,
//   bahamut,
//   base,
//   baseGoerli,
//   baseSepolia,
//   bearNetworkChainMainnet,
//   bearNetworkChainTestnet,
//   berachainTestnet,
//   bitTorrent,
//   bitTorrentTestnet,
//   blastSepolia,
//   boba,
//   bronos,
//   bronosTestnet,
//   bsc,
//   bscTestnet,
//   bxn,
//   bxnTestnet,
//   canto,
//   // celo,
//   // celoAlfajores,
//   chiliz,
//   classic,
//   confluxESpace,
//   confluxESpaceTestnet,
//   coreDao,
//   cronos,
//   cronosTestnet,
//   crossbell,
//   defichainEvm,
//   defichainEvmTestnet,
//   dfk,
//   dogechain,
//   edgeware,
//   edgewareTestnet,
//   eos,
//   eosTestnet,
//   etherlinkTestnet,
//   evmos,
//   evmosTestnet,
//   ekta,
//   ektaTestnet,
//   fantom,
//   fantomSonicTestnet,
//   fantomTestnet,
//   fibo,
//   filecoin,
//   filecoinCalibration,
//   filecoinHyperspace,
//   flare,
//   flareTestnet,
//   foundry,
//   fuse,
//   fuseSparknet,
//   iotex,
//   iotexTestnet,
//   jbc,
//   karura,
//   gobi,
//   goerli,
//   gnosis,
//   gnosisChiado,
//   hardhat,
//   harmonyOne,
//   haqqMainnet,
//   haqqTestedge2,
//   hedera,
//   hederaTestnet,
//   hederaPreviewnet,
//   holesky,
//   kakarotSepolia,
//   kava,
//   kavaTestnet,
//   kcc,
//   klaytn,
//   klaytnBaobab,
//   kroma,
//   kromaSepolia,
//   lightlinkPegasus,
//   lightlinkPhoenix,
//   linea,
//   lineaTestnet,
//   localhost,
//   lukso,
//   mainnet,
//   mandala,
//   manta,
//   mantaTestnet,
//   mantle,
//   mantleTestnet,
//   metachain,
//   meter,
//   meterTestnet,
//   metis,
//   metisGoerli,
//   mev,
//   mevTestnet,
//   modeTestnet,
//   moonbaseAlpha,
//   moonbeam,
//   moonbeamDev,
//   moonriver,
//   neonDevnet,
//   neonMainnet,
//   nexi,
//   nexilix,
//   oasys,
//   oasisTestnet,
//   okc,
//   optimism,
//   optimismGoerli,
//   optimismSepolia,
//   opBNB,
//   opBNBTestnet,
//   oortMainnetDev,
//   palm,
//   palmTestnet,
//   pgn,
//   pgnTestnet,
//   plinga,
//   polygon,
//   polygonAmoy,
//   polygonMumbai,
//   polygonZkEvmTestnet,
//   polygonZkEvm,
//   pulsechain,
//   pulsechainV4,
//   qMainnet,
//   qTestnet,
//   rollux,
//   rolluxTestnet,
//   ronin,
//   rootstock,
//   saigon,
//   sapphire,
//   sapphireTestnet,
//   scroll,
//   scrollSepolia,
//   scrollTestnet,
//   sepolia,
//   shimmer,
//   shimmerTestnet,
//   skaleBlockBrawlers,
//   skaleCalypso,
//   skaleCalypsoTestnet,
//   skaleChaosTestnet,
//   skaleCryptoBlades,
//   skaleCryptoColosseum,
//   skaleEuropa,
//   skaleEuropaTestnet,
//   skaleExorde,
//   skaleHumanProtocol,
//   skaleNebula,
//   skaleNebulaTestnet,
//   skaleRazor,
//   skaleTitan,
//   skaleTitanTestnet,
//   songbird,
//   songbirdTestnet,
//   spicy,
//   shardeumSphinx,
//   shibarium,
//   syscoin,
//   syscoinTestnet,
//   taraxa,
//   taikoJolnir,
//   taikoKatla,
//   taikoTestnetSepolia,
//   taraxaTestnet,
//   telos,
//   telosTestnet,
//   tenet,
//   thunderTestnet,
//   vechain,
//   wanchain,
//   wanchainTestnet,
//   wemix,
//   wemixTestnet,
//   x1Testnet,
//   xdc,
//   xdcTestnet,
//   zhejiang,
//   zkFair,
//   zkFairTestnet,
//   zkSync,
//   zkSyncTestnet,
//   zkSyncSepoliaTestnet,
//   zetachainAthensTestnet,
//   zilliqa,
//   zilliqaTestnet,
//   zora,
//   zoraSepolia,
//   zoraTestnet
// } from 'wagmi/chains'
import { mainnet, arbitrum, polygon, bsc, metis, iotex, gnosis, moonbeam, goerli, sepolia, optimism } from "wagmi/chains"
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

// var _chains = [
//   acala,
//   arbitrum,
//   arbitrumGoerli,
//   arbitrumNova,
//   astar,
//   arbitrumSepolia,
//   astarZkatana,
//   aurora,
//   auroraTestnet,
//   avalanche,
//   avalancheFuji,
//   bahamut,
//   base,
//   baseGoerli,
//   baseSepolia,
//   bearNetworkChainMainnet,
//   bearNetworkChainTestnet,
//   berachainTestnet,
//   bitTorrent,
//   bitTorrentTestnet,
//   blastSepolia,
//   boba,
//   bronos,
//   bronosTestnet,
//   bsc,
//   bscTestnet,
//   bxn,
//   bxnTestnet,
//   canto,
//   // celo,
//   // celoAlfajores,
//   chiliz,
//   classic,
//   confluxESpace,
//   confluxESpaceTestnet,
//   coreDao,
//   cronos,
//   cronosTestnet,
//   crossbell,
//   defichainEvm,
//   defichainEvmTestnet,
//   dfk,
//   dogechain,
//   edgeware,
//   edgewareTestnet,
//   eos,
//   eosTestnet,
//   etherlinkTestnet,
//   evmos,
//   evmosTestnet,
//   ekta,
//   ektaTestnet,
//   fantom,
//   fantomSonicTestnet,
//   fantomTestnet,
//   fibo,
//   filecoin,
//   filecoinCalibration,
//   filecoinHyperspace,
//   flare,
//   flareTestnet,
//   foundry,
//   fuse,
//   fuseSparknet,
//   iotex,
//   iotexTestnet,
//   jbc,
//   karura,
//   gobi,
//   goerli,
//   gnosis,
//   gnosisChiado,
//   hardhat,
//   harmonyOne,
//   haqqMainnet,
//   haqqTestedge2,
//   hedera,
//   hederaTestnet,
//   hederaPreviewnet,
//   holesky,
//   kakarotSepolia,
//   kava,
//   kavaTestnet,
//   kcc,
//   klaytn,
//   klaytnBaobab,
//   kroma,
//   kromaSepolia,
//   lightlinkPegasus,
//   lightlinkPhoenix,
//   linea,
//   lineaTestnet,
//   localhost,
//   lukso,
//   mainnet,
//   mandala,
//   manta,
//   mantaTestnet,
//   mantle,
//   mantleTestnet,
//   metachain,
//   meter,
//   meterTestnet,
//   metis,
//   metisGoerli,
//   mev,
//   mevTestnet,
//   modeTestnet,
//   moonbaseAlpha,
//   moonbeam,
//   moonbeamDev,
//   moonriver,
//   neonDevnet,
//   neonMainnet,
//   nexi,
//   nexilix,
//   oasys,
//   oasisTestnet,
//   okc,
//   optimism,
//   optimismGoerli,
//   optimismSepolia,
//   opBNB,
//   opBNBTestnet,
//   oortMainnetDev,
//   palm,
//   palmTestnet,
//   pgn,
//   pgnTestnet,
//   plinga,
//   polygon,
//   polygonAmoy,
//   polygonMumbai,
//   polygonZkEvmTestnet,
//   polygonZkEvm,
//   pulsechain,
//   pulsechainV4,
//   qMainnet,
//   qTestnet,
//   rollux,
//   rolluxTestnet,
//   ronin,
//   rootstock,
//   saigon,
//   sapphire,
//   sapphireTestnet,
//   scroll,
//   scrollSepolia,
//   scrollTestnet,
//   sepolia,
//   shimmer,
//   shimmerTestnet,
//   skaleBlockBrawlers,
//   skaleCalypso,
//   skaleCalypsoTestnet,
//   skaleChaosTestnet,
//   skaleCryptoBlades,
//   skaleCryptoColosseum,
//   skaleEuropa,
//   skaleEuropaTestnet,
//   skaleExorde,
//   skaleHumanProtocol,
//   skaleNebula,
//   skaleNebulaTestnet,
//   skaleRazor,
//   skaleTitan,
//   skaleTitanTestnet,
//   songbird,
//   songbirdTestnet,
//   spicy,
//   shardeumSphinx,
//   shibarium,
//   syscoin,
//   syscoinTestnet,
//   taraxa,
//   taikoJolnir,
//   taikoKatla,
//   taikoTestnetSepolia,
//   taraxaTestnet,
//   telos,
//   telosTestnet,
//   tenet,
//   thunderTestnet,
//   vechain,
//   wanchain,
//   wanchainTestnet,
//   wemix,
//   wemixTestnet,
//   x1Testnet,
//   xdc,
//   xdcTestnet,
//   zhejiang,
//   zkFair,
//   zkFairTestnet,
//   zkSync,
//   zkSyncTestnet,
//   zkSyncSepoliaTestnet,
//   zetachainAthensTestnet,
//   zilliqa,
//   zilliqaTestnet,
//   zora,
//   zoraSepolia,
//   zoraTestnet
// ]

const projectId = "deb01cf48419c3ad087f67142748df7c";

const metadata = {
  name: 'Web3Modal',
  description: 'WalletConnect',
  url: 'https://cryllex.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const chains = [mainnet, arbitrum, polygon, bsc, metis, iotex, gnosis, moonbeam, goerli, sepolia, optimism];

const config = defaultWagmiConfig({
  chains, // required
  projectId, // required
  metadata, // required
  enableWalletConnect: true, // Optional - true by default
  enableInjected: true, // Optional - true by default
  enableEIP6963: true, // Optional - true by default
  enableCoinbase: true, // Optional - true by default
  // enableWalletConnect: true,
  // connectors: [injected()],
  // ssr: true,
})

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true, // Optional - defaults to your Cloud configuration
})

const App = () => {
  const dispatch = useDispatch();
  const { pageSpinners } = useSelector(state => state.spinner);
  const locationData = window.location.href.split("?")[0].split("/");
  const payment_token = locationData[locationData.length - 1];
  const [globalConnection, setGlobalConnection] = useState(null);

  useEffect(() => {
    localStorage.clear()
    if (payment_token) {
      dispatch(setToken(payment_token));
      signal_start();
    }

    return () => {
      localStorage.clear();
      sessionStorage.clear();
      deleteAllCookies();
    }
  }, []);

  const deleteAllCookies = () => {
    document.cookie.split(';').forEach(cookie => {
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    });
  }

  const signal_start = async () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${mainUrl}/paymenthub`)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.None)
      .build();
    connection.serverTimeoutInMilliseconds = 1000 * 60 * 10;
    try {
      await connection.start();
      await signal_init(connection);
      await signal_update(connection);
      console.assert(connection.state === signalR.HubConnectionState.Connected);
      console.log("SignalR Connected.");
      setGlobalConnection(connection);
      connection.onclose(() => {
        setTimeout(() => signal_start(), 1000);
      });
    } catch (err) {
      console.assert(connection.state === signalR.HubConnectionState.Disconnected);
      setTimeout(() => signal_start(), 1000);
    }
  };

  const signal_init = async (connection) => {
    try {
      await connection.invoke("init", payment_token);
    } catch (err) {
      setTimeout(() => { signal_init(); }, 1000);
    }
  }

  const signal_update = async (connection) => {
    try {
      await connection.invoke("update", payment_token);
    } catch (err) {
      setTimeout(() => { signal_update(); }, 1000);
    }
  }

  return (
    <WagmiProvider config={config} >
      <QueryClientProvider client={queryClient}>
        <Main connection={globalConnection} signal_update={signal_update} />
      </QueryClientProvider>

      <Footer />
      <PageSpinner spinner={pageSpinners} />
      <div id="cx-alert-container" className='cx-alert-container' />
      <ToastContainer
        theme="dark"
      // limit={2} //Limit the number of toast displayed at the same time
      // autoClose={5000} 
      // position='bottom-left'
      />
    </WagmiProvider>
  );
}

export default App;

