import React from 'react';
import ReactDOM from 'react-dom/client';
import './css/styles.css';
import App from './App';
import { Provider } from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import reducer from './Store/Reducers';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore({
  reducer,
  middleware: getDefaultMiddleware()
})

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);

