import React, { useEffect, useState, memo } from "react"
import { Accordion, Button } from 'react-bootstrap'
import MainPrice from "./components/MainPrice/MainPrice"
import PayBtn from "./components/PayBtn/PayBtn"
import ApiService from './../../Services/ApiService'
import AlertService from './../../Services/AlertService'
import MainService from './../../Services/MainService'
import { useSelector } from 'react-redux'
import TransactionWaiting from "../TransactionWaiting"
import TopLogo from "../TopLogo"
import $ from "jquery"
import * as signalR from '@microsoft/signalr'
import { isAndroid, isIOS } from "react-device-detect"
import { _countries, extractAddress } from "./components/helper"
import Web3 from "./components/Web3/Web3"
import KycVerification from "./components/KycVerification/KycVerification"
import OTP from "./components/OTP/OTP"
import Coin from "./components/Coin/Coin"
import { useDebounce } from 'use-debounce'
import { BiSolidErrorAlt } from "react-icons/bi"
import CountdownTimer from "./components/CountdownTimer/CountdownTimer"

import {
    useSendTransaction,
    useWaitForTransactionReceipt,
    useAccount,
    useBalance,
    useConnect,
    useDisconnect,
    useSignMessage,
    useWalletClient,
    usePrepareSendTransaction,
    useSwitchChain,
    useChains,
    useSwitchAccount,
} from 'wagmi'

import { useWeb3Modal, useWeb3ModalEvents, useWeb3ModalState, useWeb3ModalTheme } from '@web3modal/wagmi/react'

import { parseEther, createPublicClient, http } from 'viem'
import axios from "axios"
import { mainUrl } from "../../Constants/ApiKeys"

// var paymentData = null;

const Main = memo((props) => {

    const { connection } = props;
    var isPaymentCancelled = false;
    const { token } = useSelector(state => state.main);
    const [isLoading, setIsLoading] = useState(false);
    const [showOTPloader, setShowOTPloader] = useState(false);
    const [price, setPrice] = useState(null);
    const [tick, setTick] = useState(null);
    const [selectedCrypto, setSelectedCrypto] = useState(null);
    const [selectedWallet, setSelectedWallet] = useState(null);
    const [activeKeys, setActiveKeys] = useState([1]);
    const [cryptosList, setCryptosList] = useState([]);
    const [walletsList, setWalletsList] = useState([]);
    const [organizationData, setOrganizationData] = useState(null);
    const [isTransactionValidated, setIsTransactionValidated] = useState(false);
    const [isShowTransactionWaitingModal, setIsShowTransactionWaitingModal] = useState(false);
    const [kyc_numbers, set_kyc_numbers] = useState("");
    const [debounce_kyc_numbers] = useDebounce(kyc_numbers, 500);
    const [isShowSMSwaitingInfoMsg, setIsShowSMSwaitingInfoMsg] = useState(false);
    const [selectedAccordionKeys, setSelectedAccordionKeys] = useState([]);
    const [paymentData, setPaymentData] = useState(null);

    const [countries, setCountries] = useState([]);
    const [values, setValues] = useState({
        residentialAddress: "",
        firstName: "",
        lastName: "",
        email: "",
        selectedCountryId: null,
    })

    const [isInvalidFirstName, setIsInvalidFirstName] = useState(false);
    const [isInvalidLastName, setIsInvalidLastName] = useState(false);
    const [isInvalidEmail, setIsInvalidEmail] = useState(false);
    const [to, setTo] = useState(null);
    const [isSupportedCrypto, setIsSupportedCrypto] = useState(false);
    const [allBalance, setAllBalance] = useState(null);
    const [isKycSent, setIsKycSent] = useState(false);

    const [amount, setAmount] = useState(0);
    const { disconnect } = useDisconnect();

    const state = useWeb3ModalState()
    const { themeMode, themeVariables, setThemeMode } = useWeb3ModalTheme()
    const events = useWeb3ModalEvents()
    const { open: openModal, close: closeModal } = useWeb3Modal()

    const { data: signData, error, signMessage, signMessageAsync, isLoading: isLoadingSignMessage, isIdle, status: messageStatus } = useSignMessage();

    const {
        address: addressAccount,
        addresses: addressesAccount,
        chain,
        chainId: chainIdAccount,
        connector: connectorAccount,
        isConnected,
        isReconnecting: isReconnectingAccount,
        isConnecting: isConnectingAccount,
        isDisconnected: isDisconnectedAccount,
        status: statusAccount,
    } = useAccount();

    const {
        data: dataBalance,
        error: errorBalance,
        isError: isErrorBalance,
        isPending: isPendingBalance,
        isLoadingError: isLoadingErrorBalance,
        isRefetchError: isRefetchErrorBalance,
        isSuccess: isSuccessBalance,
        status: statusBalance,
        queryKey,
    } = useBalance({ address: addressAccount ? addressAccount : "", })

    const result = useBalance({
        address: addressAccount,
    })

    const {
        data: walletClient,
        dataUpdatedAt: dataUpdatedAtWalletClient,
        error: errorWalletClient,
        errorUpdatedAt: errorUpdatedAtWalletClient,
        failureCount: failureCountWalletClient,
        failureReason: failureReasonWalletClient,
        errorUpdateCount: errorUpdateCountWalletClient,
        isError: isErrorWalletClient,
        isFetched: isFetchedWalletClient,
        isFetchedAfterMount: isFetchedAfterMountWalletClient,
        isFetching: isFetchingWalletClient,
        isPending: isPendingWalletClient,
        isLoadingError: isLoadingErrorWalletClient,
        isInitialLoading: isInitialLoadingWalletClient,
        isPaused: isPausedWalletClient,
        isPlaceholderData: isPlaceholderDataWalletClient,
        isRefetchError: isRefetchErrorWalletClient,
        isRefetching: isRefetchingWalletClient,
        isStale: isStaleWalletClient,
        isSuccess: isSuccessWalletClient,
        status: statusWalletClient,
        fetchStatus: fetchStatus,
    } = useWalletClient();

    const {
        // connect,
        // connectors,
        data: dataConnect,
        variables: variables,
        error: errorConnect,
        isError: isErrorConnect,
        isIdle: isIdleConnect,
        isPending: isPendingConnect,
        isSuccess: isSuccessConnect,
        status: statusConnect,
    } = useConnect();

    const { connectors, switchAccount } = useSwitchAccount();

    const {
        data: transactionData,
        error: transactionError,
        isSuccess: transactionSuccess,
        isPending: transactionIsPending,
        sendTransaction
    } = useSendTransaction();

    useEffect(() => {
        if (transactionError) {
            if (JSON.stringify(transactionError).includes("UserRejectedRequestError")) {
                cancelPaymentWithoutAlertConfirm();
            }
        }
    }, [transactionError])

    const { chains, switchChain } = useSwitchChain()

    useEffect(() => {
        chain && addressAccount && getAccountBalance(chain.id, addressAccount);
    }, [chain])

    useEffect(() => {
        if (debounce_kyc_numbers.length === 8) {
            validateKyc(debounce_kyc_numbers);
        }
    }, [debounce_kyc_numbers])

    useEffect(() => {
        // if (transactionData && transactionData.hash && transactionData.hash.length && transactionSuccess) {
        if (transactionSuccess && transactionData) {
            if (typeof transactionData === "object" && transactionData.hash && transactionData.hash.length) {
                paySuccess(transactionData.hash);
            } else {
                paySuccess(transactionData);
            }
        }
    }, [transactionData, transactionSuccess])

    useEffect(() => {
        if (cryptosList.length && chain && chain.nativeCurrency && chain.nativeCurrency.symbol) {
            let crypto = cryptosList.find(item => item.name === chain.nativeCurrency.symbol)
            if (crypto) selectCrypto(crypto);
            else setSelectedCrypto(null);
        } else {
            setSelectedCrypto(null);
        }
    }, [cryptosList, chain])

    useEffect(() => {
        if (cryptosList.length && walletsList.length) {
            analyzePaymentData();
        }
    }, [cryptosList, walletsList]);

    useEffect(() => {
        if (connection && connection.state === signalR.HubConnectionState.Connected) {
            init();
            connection.on("updatecrypto", (id, _price) => {
                try {
                    if (_price) {
                        setPrice(JSON.parse(_price).price)
                        setTick(JSON.parse(_price).tick)
                    }
                } catch (error) {
                    AlertService.alert("error", error)
                }
            });
        }

    }, [connection])

    useEffect(() => {
        getCountries();
        return () => {
            setCountries([]);
        }
    }, [])

    useEffect(() => {
        let wallet = walletsList.find(item => item.name === "WalletConnect")
        if (wallet && isConnected) selectWallet(wallet);
        else setSelectedWallet(null);
    }, [isConnected, walletsList])

    useEffect(() => {
        if (cryptosList.length && chain && chain.nativeCurrency && chain.nativeCurrency.symbol) {
            setIsSupportedCrypto(!!cryptosList.find(item => item.name === chain.nativeCurrency.symbol))
        }
    }, [cryptosList, chain])

    const getAccountBalance = async (chainId, address) => {
        try {
            const allBalance = await axios.get(`${mainUrl}/api/Etherscan/GetBalance?addressHash=${address}`);
            if (allBalance && allBalance.data && allBalance.data.data) {
                setAllBalance(allBalance.data.data)
            }
        } catch (error) {
            AlertService.alert("error", error)
        }
        // ApiService.getAccountBalance(chainId, addressAccount).then(response => {
        // if (response && response.data) {
        //     setAccountBalance(response.data)
        // }
        // }).catch(error => getFail(error))
    }

    const onChange = (event, fieldName) => {
        setValues(values => ({ ...values, [fieldName]: event.target.value }))
    }

    const onPhoneNumberChange = (event, fieldName) => {
        setValues(values => ({ ...values, [fieldName]: event }))
    };

    const onSelectCountry = (selectedItemId, fieldName) => {
        if (!selectedItemId) { return false };
        setValues(values => ({ ...values, [fieldName]: selectedItemId }))
    }

    const init = () => {
        try {
            getCryptos();
            getWallets();
            setTimeout(() => {
                verifyIsKycAlreadyDone();
            }, 500);
        } catch (e) {
            setTimeout(() => { init(); }, 1000);
        }
    }

    const getCryptos = () => {
        ApiService.getCryptos().then(response => {
            if (response && response.data) {
                setCryptosList(response.data);
            }
        }).catch(error => getFail(error));
    }

    const getCountries = () => {
        ApiService.getCountries().then(response => {
            if (response && response.data) {
                setCountries(response.data);
            }
        }).catch(error => getFail(error));
    }

    const getWallets = () => {
        ApiService.getWallets().then(response => {
            if (response && response.data) setWalletsList(response.data);
        }).catch(error => getFail(error));
    }

    const verifyIsKycAlreadyDone = () => {
        ApiService.verifyIsKycAlreadyDone().then(response => {
            if (response && response.data) setIsTransactionValidated(true);
        }).catch(error => getFail(error));
    }

    const analyzePaymentData = (_loadOrgData) => {
        if (_loadOrgData == undefined) _loadOrgData = true;
        if (!token) { return false; }
        ApiService.analyzePaymentData(token).then(response => {
            if (response && response.data) {
                const data = { ...response.data };
                if (data.cancelUrl && isPaymentCancelled) {
                    window.location.href = data.cancelUrl;
                }
                successFunc(data, _loadOrgData)
            };
        }).catch(error => getFail(error))
    }

    const successFunc = (data, _loadOrgData) => {
        // paymentData = null;
        setPaymentData(null);
        setIsShowTransactionWaitingModal(false);
        setTimeout(() => {
            // paymentData = data;
            setPaymentData(data);
            data.cryptoAmount && setPrice(data.cryptoAmount);
            if (data.cryptoCurrencyId) {
                setSelectedCrypto(cryptosList.find(el => +el.id === +data.cryptoCurrencyId));
            }
            if (data.cryptoWalletId) {
                var selectedWallet = walletsList.find(el => +el.id === +data.cryptoWalletId)
                setSelectedWallet(selectedWallet);
                // inf_setWallet(selectedWallet);
            }
            if (data.paymentRequested) {
                setIsShowTransactionWaitingModal(true);
                paymentStatusChecker();
            }

            if (_loadOrgData) {
                loadOrgData(data.organizationId);
            }
        }, 500);
    };

    const paymentStatusChecker = () => {
        try {
            // var processed = pdata.processed;
            // var ErrCode = pdata.errorCode;
            // var ErrorMessage = pdata.errorMessage;
            // var txHash = pdata.cryptoTransactionHash;
            // var CryptoPayerAddress = pdata.cryptoPayerAddress;

            // $('#payReqTimer').html(MainService.secondsToString(5 * 60 - passedSeconds));
            // var paymentTime = paymentData?.paymentRequestedDatetime;
            // if (paymentTime && $('#payReqTimer')) {
            //     debugger
            //     var ptimeNow = MainService.convertUTCDateToLocalDate(new Date(paymentTime));
            //     var passedSeconds = Math.floor(Date.now() / 1000 - ptimeNow.getTime() / 1000);
            //     var timeRemaining = 5 * 60 - passedSeconds;
            //     $('#payReqTimer').html(MainService.secondsToString(timeRemaining));
            // }


            var paymentTime = paymentData?.paymentRequestedDatetime;
            var ptimeNow = MainService.convertUTCDateToLocalDate(new Date(paymentTime));
            var passedSeconds = parseInt(parseInt(Date.now() / 1000) - parseInt(ptimeNow.getTime() / 1000));
            if (passedSeconds && passedSeconds > 300 & isShowTransactionWaitingModal && paymentData.status === 0) {
                cancelPaymentWithoutAlertConfirm()
            }

        } catch (error) {
            AlertService.alert("error", error);
        } finally {
            // if (paymentData && (paymentData.status <= 1 || paymentData.status === 5 || paymentData.status === 3)) {
            if (paymentData && typeof paymentData.status === "number") {
                setTimeout(() => {
                    ApiService.analyzePaymentData(token).then(response => {
                        if (response && response.data) {
                            if (JSON.stringify(response.data) !== JSON.stringify(paymentData)) {
                                const data = { ...response.data };
                                // paymentData = data;
                                setPaymentData(data);
                            }
                        }
                        paymentStatusChecker();
                    })
                }, 1000);
            }
        }
    }

    const loadOrgData = (organizationId) => {
        setIsLoading(true);
        ApiService.loadOrgData(organizationId).then(response => {
            if (response && response.data) {
                setOrganizationData(response.data.organizationDetail)
            }
            setIsLoading(false)
        }).catch(error => getFail(error));
    }

    const validateKyc = (data) => {
        setShowOTPloader(true);
        ApiService.validateKyc({ Code: data.toString() }).then(response => {
            if (response && response.data) {
                setIsTransactionValidated(true);
                verifyIsKycAlreadyDone();
                if (!selectedAccordionKeys.find(key => +key === 4)) {
                    setActiveKeys(activeKeys.filter(key => +key !== 4));
                }
            } else {
                AlertService.alert("error", "OTP code is invalid");
            }
            setTimeout(() => {
                setShowOTPloader(false);
                clearOTPInputs();
            }, 1000);
        }).catch(error => getFail(error))
    }

    const sendPhoneNumber = () => {
        setIsLoading(true);
        let activeKeysCopy = [...activeKeys];
        setIsShowSMSwaitingInfoMsg(true);
        const data = {
            Phone: values.phoneNumber,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            residentialAddress: values.residentialAddress,
            country: values.selectedCountryId,
        }
        ApiService.sendPhoneNumber(data).then(() => {
            setIsShowSMSwaitingInfoMsg(false);
            AlertService.alert("success", "The verification code has been sent.");
            // if (activeKeysCopy.find(key => +key === 3) && !selectedAccordionKeys.find(key => +key === 3)) {
            //     activeKeysCopy = activeKeysCopy.filter(key => +key !== 3);
            // }
            activeKeysCopy = activeKeysCopy.filter(key => +key !== 3);

            if (!activeKeysCopy.find(key => +key === 4)) {
                activeKeysCopy = [...activeKeysCopy, 4];
            }
            if (document.getElementById("kyc_0")) {
                setTimeout(() => {
                    document.getElementById("kyc_0").focus();
                }, 500);
            };
            setActiveKeys(activeKeysCopy);
            setIsLoading(false);
            setIsKycSent(true);
        }).catch(error => getFail(error));
    }

    const checkFieldValidation = (field, fieldName) => {
        const isValid = MainService.isValidField(field, fieldName);
        switch (fieldName) {
            case "firstName":
                checkIsinvalidFieldName(isValid, "isInvalidFirstName")
                break;
            case "lastName":
                checkIsinvalidFieldName(isValid, "isInvalidLastName")
                break;
            case "email":
                checkIsinvalidFieldName(isValid, "isInvalidEmail")
                break;
            default:
                break;
        }
    }

    const checkIsinvalidFieldName = (isValid, name) => {
        switch (name) {
            case "isInvalidFirstName":
                setIsInvalidFirstName(!isValid);
                break;
            case "isInvalidLastName":
                setIsInvalidLastName(!isValid);
                break;
            case "isInvalidEmail":
                setIsInvalidEmail(!isValid);
                break;
            default:
                break;
        }
    }

    const selectCrypto = (selectedCrypto) => {
        let activeKeysCopy = [...activeKeys];
        ApiService.selectCrypto({ cryptoCurrencyId: selectedCrypto.id.toString() }).then(response => {
            setSelectedCrypto(selectedCrypto);
            if (!selectedAccordionKeys.find(key => +key === 1)) {
                activeKeysCopy = activeKeysCopy.filter(key => +key !== 1);
            }
            activeKeysCopy = [...activeKeysCopy, 2];
            setActiveKeys(activeKeysCopy);
        }).catch(error => getFail(error));
    }

    const selectWallet = (selectedWallet) => {
        ApiService.selectWallet({ CryptoWalletId: selectedWallet.id.toString() }).then(response => {
            setSelectedWallet(selectedWallet);
            // inf_setWallet(selectedWallet);
        }).catch(error => getFail(error));
    }

    const pay = () => {
        if (!selectedWallet) {
            AlertService.alert("warning", "Please select any wallet.");
            return false;
        }
        if (!isConnected) {
            AlertService.alert("warning", "Please connect any wallet.");
            return false;
        }
        ApiService.pay().then(resp => {
            if (resp && resp.data) {
                const data = { ...resp.data }
                if (isAndroid || isIOS) {
                    const deepLinkUrl = `metamask://${data.account}/${data.amount}`;
                    window.location.replace(deepLinkUrl);
                }
                props.signal_update();
                setTo(data.account);
                setAmount(data.amount)
                analyzePaymentData(false);
                setTimeout(() => {
                    sendTransaction({ to: data.account, value: parseEther(data.amount.toString()) })
                }, 500);
            }
        }).catch(error => getFail(error));
    }

    const paySuccess = (txHash) => {
        ApiService.paySuccess({ TxHash: txHash }).then(() => {
            analyzePaymentData(false);
            props.signal_update();
        }).catch(error => getFail(error))
    }

    const payError = (err_code, err_message) => {
        ApiService.payError({ Code: `${err_code}`, Message: err_message }).then(() => {
            analyzePaymentData(false);
            props.signal_update();
        }).catch(error => getFail(error))
    }

    const cancelPayment = () => {
        AlertService.alertConfirm(
            `Payment cancellation`,
            "Are you sure you want to cancel this payment ?",
            "Yes",
            "No"
        ).then(() => {
            ApiService.cancelPayment(token).then(() => {
                isPaymentCancelled = true;
                analyzePaymentData(false);
                setIsLoading(false);
                setIsTransactionValidated(false);
                setShowOTPloader(false);
                clearOTPInputs();
            })
        })
    }

    const cancelPaymentWithoutAlertConfirm = () => {
        ApiService.cancelPayment(token).then(() => {
            isPaymentCancelled = true;
            analyzePaymentData(false);
            setIsLoading(false);
            setIsTransactionValidated(false);
            setShowOTPloader(false);
            clearOTPInputs();
        })
    }

    const getFail = (error) => {
        error && AlertService.alert("error", error);
        isPaymentCancelled = false;
        setIsLoading(false);
        clearOTPInputs();
        setIsShowSMSwaitingInfoMsg(false);
        setTimeout(() => {
            setShowOTPloader(false);
        }, 1000);
    }

    const clearOTPInputs = () => {
        set_kyc_numbers("");
        if ($(document.querySelectorAll('.kyc-number')[0])) {
            $(document.querySelectorAll('.kyc-number')[0]).focus();
        };
    }

    return (
        connection && connection.state === signalR.HubConnectionState.Connected ?
            <>
                {/* <CountdownTimer initialSeconds={30} /> */}
                <TopLogo />
                <div className="container scrollbar" id="main">
                    <div className={`cx-window-container`}>
                        <div className="cx-window-wrapper">
                            <TransactionWaiting
                                connection={connection}
                                paymentData={paymentData}
                                cancelPayment={cancelPayment}
                                transactionData={transactionData}
                                isShowTransactionWaitingModal={isShowTransactionWaitingModal}
                            />
                            <MainPrice
                                selectedWallet={selectedWallet}
                                selectedCrypto={selectedCrypto}
                                paymentData={paymentData}
                                tick={tick}
                                chain={chain}
                                price={price}
                                organizationData={organizationData}
                            />
                            <div className={`cx-window-right-side ${organizationData && paymentData ? "" : "disabledd"}`}>
                                <div className="cx-window-body-container">
                                    <div className={`accordion`} id="cryllexAccordion">
                                        <Accordion
                                            alwaysOpen
                                            activeKey={activeKeys}
                                            onSelect={(event) => {
                                                setActiveKeys(event);
                                            }}
                                        >
                                            <Accordion.Item
                                                eventKey={1}
                                            >
                                                <Accordion.Header
                                                    className={isLoading ? "disabled-accordion" : ""}
                                                    onClick={() => {
                                                        if (selectedAccordionKeys.find(key => +key === 1)) {
                                                            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 1))
                                                        } else {
                                                            setSelectedAccordionKeys([...selectedAccordionKeys, 1])
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/images/crillex.svg" alt="" />
                                                    {
                                                        addressAccount ? `Wallet address ${extractAddress(addressAccount)}` : " Connect your wallet"
                                                    }
                                                    {
                                                        !isSupportedCrypto && chain ?
                                                            <BiSolidErrorAlt className="ms-2" color="#DC3545" />
                                                            : null
                                                    }
                                                </Accordion.Header>
                                                <Accordion.Body>

                                                    {/* <div>
                                                        <button onClick={() => openModal()}>Open Connect Modal</button>
                                                        <button onClick={() => openModal({ view: 'Networks' })}>Open Network Modal</button>
                                                    </div>
                                                    --------------------------------------------------- */}
                                                    <w3m-button className="wallet-connect-button" />
                                                    {
                                                        isConnected ?
                                                            <div style={{ height: "1px", width: "100%", backgroundColor: "#fff", marginTop: "20px", opacity: .5 }} />
                                                            : null
                                                    }
                                                    <Web3
                                                        to={to}
                                                        amount={amount}
                                                        isSupportedCrypto={isSupportedCrypto}
                                                        cryptosList={cryptosList}
                                                        chain={chain}
                                                        allBalance={allBalance}
                                                        transactionError={transactionError}
                                                        transactionData={transactionData}
                                                        transactionIsSuccess={transactionSuccess}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item
                                                eventKey={2}
                                            >
                                                <Accordion.Header
                                                    className={isLoading ? "disabled-accordion" : ""}
                                                    onClick={() => {
                                                        if (selectedAccordionKeys.find(key => +key === 2)) {
                                                            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 2))
                                                        } else {
                                                            setSelectedAccordionKeys([...selectedAccordionKeys, 2])
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/images/crillex.svg" alt="" />
                                                    Coins supported by our platform
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    {
                                                        cryptosList && (<Coin
                                                            cryptosList={cryptosList}
                                                        />)
                                                    }
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item
                                                eventKey={3}
                                            >
                                                <Accordion.Header
                                                    className={isLoading ? "disabled-accordion" : ""}
                                                    onClick={() => {
                                                        if (document.getElementById("firstName")) {
                                                            setTimeout(() => {
                                                                document.getElementById("firstName").focus();
                                                            }, 500);
                                                        };
                                                        if (selectedAccordionKeys.find(key => +key === 3)) {
                                                            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 3))
                                                        } else {
                                                            setSelectedAccordionKeys([...selectedAccordionKeys, 3])
                                                        }
                                                    }}
                                                >
                                                    <img src="/assets/images/crillex.svg" alt="" />
                                                    {
                                                        isTransactionValidated ? "KYC verified" : "KYC verification"
                                                    }
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <KycVerification
                                                        isLoading={isLoading}
                                                        isTransactionValidated={isTransactionValidated}
                                                        countries={countries}
                                                        isShowSMSwaitingInfoMsg={isShowSMSwaitingInfoMsg}
                                                        isInvalidFirstName={isInvalidFirstName}
                                                        isInvalidEmail={isInvalidEmail}
                                                        isInvalidLastName={isInvalidLastName}
                                                        isKycSent={isKycSent}
                                                        values={values}
                                                        onSelectCountry={onSelectCountry}
                                                        checkFieldValidation={checkFieldValidation}
                                                        onPhoneNumberChange={onPhoneNumberChange}
                                                        sendPhoneNumber={sendPhoneNumber}
                                                        onChange={onChange}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>

                                            <Accordion.Item
                                                eventKey={4}
                                            >
                                                <Accordion.Header
                                                    className={isLoading ? "disabled-accordion" : ""}
                                                    onClick={() => {
                                                        if (document.getElementById("kyc_0")) {
                                                            setTimeout(() => {
                                                                document.getElementById("kyc_0").focus();
                                                            }, 500);
                                                        };
                                                        if (selectedAccordionKeys.find(key => +key === 4)) {
                                                            setSelectedAccordionKeys(selectedAccordionKeys.filter(key => +key !== 4))
                                                        } else {
                                                            setSelectedAccordionKeys([...selectedAccordionKeys, 4])
                                                        }

                                                    }}
                                                >
                                                    <img src="/assets/images/crillex.svg" alt="" />
                                                    {
                                                        isTransactionValidated ?
                                                            "SMS verified. You may proceed with payment." :
                                                            " Please, type OTP Code (One time password)"
                                                    }
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <OTP
                                                        isTransactionValidated={isTransactionValidated}
                                                        kyc_numbers={kyc_numbers}
                                                        showOTPloader={showOTPloader}
                                                        set_kyc_numbers={set_kyc_numbers}
                                                    />
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                    <hr className="text-white mt-0" />
                                    <PayBtn
                                        selectedCrypto={selectedCrypto}
                                        price={price}
                                        isConnected={isConnected}
                                        paymentData={paymentData}
                                        isTransactionValidated={isTransactionValidated}
                                        isSupportedCrypto={isSupportedCrypto}
                                        pay={pay}
                                    />

                                    <div className="m-5 cx-cancel-btn">
                                        <button id="cancelbtn" data-href="/delete.php?id=23" data-toggle="modal"
                                            data-target="#confirm-delete" onClick={cancelPayment}>Cancel payment</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </>

            : null
    );
})


export default Main;
