import React, { memo } from 'react'
import { useAccount } from 'wagmi';
import MainService from '../../../../Services/MainService';

const Coin = memo((props) => {

  const { cryptosList } = props;
  const { chain } = useAccount();

  return (
    <ul className="cx-accordion-body-list" id="crypto-block">
      {
        cryptosList.map(el => {
          return <li
            key={el.id}
            // className={`cx-accordion-list-item ${selectedCrypto && +selectedCrypto.id === +el.id ? "selected-item" : ""}`}
            className={`
              cx-accordion-list-item 
              ${chain && chain.nativeCurrency && chain.nativeCurrency.symbol === el.name ? "selected-item" : ""}
            `}
            rel={el.id}
            id={`crypto_${el.id}`}
          // onClick={() => {
          //   if (!selectedCrypto || (selectedCrypto && +el.id !== +selectedCrypto.id)) {
          //     selectCrypto(el);
          //   }
          // }}
          >
            {MainService.getCoinImage(el.name)}
            <div className="cx-accordion-list-item-name">
              {el.name}
              <span>{el.symbol}</span>
            </div>
          </li>
        })
      }
    </ul>
  )
})
export default Coin