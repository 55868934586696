import React, { memo } from 'react';
import MainService from '../../../../Services/MainService';

const PayBtn = memo((props) => {

    const { price, paymentData, selectedCrypto, isTransactionValidated, pay, isSupportedCrypto, isConnected } = props;
    return paymentData ? <div className="m-5">
        <div
            id="pay"
            className={`cx-pay-container ${isTransactionValidated && selectedCrypto && isSupportedCrypto && isConnected ? "cx-pay-container-active" : ""}`}
            // className={`cx-pay-container ${isTransactionValidated && isSupportedCrypto && isConnected ? "cx-pay-container-active" : ""}`}
            onClick={() => {
                if (isTransactionValidated && selectedCrypto && isSupportedCrypto && isConnected) {
                    // if (isTransactionValidated && isSupportedCrypto && isConnected) {
                    pay();
                }
            }}>
            <div className="cx-pay-wrapper">
                <img src="/assets/icons/crillex.svg" alt="" />
                <strong>Pay</strong>
            </div>
            <div className="cx-total-wrapper">
                <p><span className="inf_fiatPrice"><b>{paymentData.fiatAmount ? `$ ${paymentData.fiatAmount}` : ""}</b></span></p>
                <span className="cx-order-list-item-value">
                    {
                        selectedCrypto ?
                            <div className='small-icon'>
                                {MainService.getCoinImage(selectedCrypto?.name)}
                            </div>
                            : null
                    }
                    {
                        price ?
                            <span className="totalcost ms-2"><b>{price}</b></span>
                            : null
                    }
                 
                </span>
            </div>
        </div>
    </div> : null
})

export default PayBtn;

