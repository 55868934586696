export const _countries = [
  {
    "name": "Afghanistan",
    "isoCode": "AFG",
    "isoCode2": "AF",
    "id": 113
  },
  {
    "name": "Albania",
    "isoCode": "ALB",
    "isoCode2": "AL",
    "id": 124
  },
  {
    "name": "Algeria",
    "isoCode": "DZA",
    "isoCode2": "DZ",
    "id": 5
  },
  {
    "name": "Argentina",
    "isoCode": "ARG",
    "isoCode2": "AR",
    "id": 54
  },
  {
    "name": "Armenia",
    "isoCode": "ARM",
    "isoCode2": "AM",
    "id": 89
  },
  {
    "name": "Australia",
    "isoCode": "AUS",
    "isoCode2": "AU",
    "id": 41
  },
  {
    "name": "Austria",
    "isoCode": "AUT",
    "isoCode2": "AT",
    "id": 33
  },
  {
    "name": "Azerbaijan",
    "isoCode": "AZE",
    "isoCode2": "AZ",
    "id": 21
  },
  {
    "name": "Bahrain",
    "isoCode": "BHR",
    "isoCode2": "BH",
    "id": 4
  },
  {
    "name": "Bangladesh",
    "isoCode": "BGD",
    "isoCode2": "BD",
    "id": 25
  },
  {
    "name": "Belarus",
    "isoCode": "BLR",
    "isoCode2": "BY",
    "id": 23
  },
  {
    "name": "Belgium",
    "isoCode": "BEL",
    "isoCode2": "BE",
    "id": 77
  },
  {
    "name": "Belize",
    "isoCode": "BLZ",
    "isoCode2": "BZ",
    "id": 42
  },
  {
    "name": "Bhutan",
    "isoCode": "BTN",
    "isoCode2": "BT",
    "id": 39
  },
  {
    "name": "Bolivia",
    "isoCode": "BOL",
    "isoCode2": "BO",
    "id": 55
  },
  {
    "name": "Bosnia & Herzegovina",
    "isoCode": "BIH",
    "isoCode2": "BA",
    "id": 28
  },
  {
    "name": "Botswana",
    "isoCode": "BWA",
    "isoCode2": "BW",
    "id": 131
  },
  {
    "name": "Brazil",
    "isoCode": "BRA",
    "isoCode2": "BR",
    "id": 114
  },
  {
    "name": "Brunei",
    "isoCode": "BRN",
    "isoCode2": "BN",
    "id": 106
  },
  {
    "name": "Bulgaria",
    "isoCode": "BGR",
    "isoCode2": "BG",
    "id": 24
  },
  {
    "name": "Cambodia",
    "isoCode": "KHM",
    "isoCode2": "KH",
    "id": 98
  },
  {
    "name": "Cameroon",
    "isoCode": "CMR",
    "isoCode2": "CM",
    "id": 80
  },
  {
    "name": "Canada",
    "isoCode": "CAN",
    "isoCode2": "CA",
    "id": 43
  },
  {
    "name": "Chile",
    "isoCode": "CHL",
    "isoCode2": "CL",
    "id": 19
  },
  {
    "name": "China",
    "isoCode": "CHN",
    "isoCode2": "CN",
    "id": 26
  },
  {
    "name": "Colombia",
    "isoCode": "COL",
    "isoCode2": "CO",
    "id": 56
  },
  {
    "name": "Congo (DRC)",
    "isoCode": "COD",
    "isoCode2": "CD",
    "id": 78
  },
  {
    "name": "Costa Rica",
    "isoCode": "CRI",
    "isoCode2": "CR",
    "id": 57
  },
  {
    "name": "Côte d’Ivoire",
    "isoCode": "CIV",
    "isoCode2": "CI",
    "id": 79
  },
  {
    "name": "Croatia",
    "isoCode": "HRV",
    "isoCode2": "HR",
    "id": 87
  },
  {
    "name": "Cuba",
    "isoCode": "CUB",
    "isoCode2": "CU",
    "id": 58
  },
  {
    "name": "Czechia",
    "isoCode": "CZE",
    "isoCode2": "CZ",
    "id": 30
  },
  {
    "name": "Denmark",
    "isoCode": "DNK",
    "isoCode2": "DK",
    "id": 32
  },
  {
    "name": "Dominican Republic",
    "isoCode": "DOM",
    "isoCode2": "DO",
    "id": 59
  },
  {
    "name": "Ecuador",
    "isoCode": "ECU",
    "isoCode2": "EC",
    "id": 60
  },
  {
    "name": "Egypt",
    "isoCode": "EGY",
    "isoCode2": "EG",
    "id": 6
  },
  {
    "name": "El Salvador",
    "isoCode": "SLV",
    "isoCode2": "SV",
    "id": 69
  },
  {
    "name": "Eritrea",
    "isoCode": "ERI",
    "isoCode2": "ER",
    "id": 129
  },
  {
    "name": "Estonia",
    "isoCode": "EST",
    "isoCode2": "EE",
    "id": 72
  },
  {
    "name": "Ethiopia",
    "isoCode": "ETH",
    "isoCode2": "ET",
    "id": 1
  },
  {
    "name": "Faroe Islands",
    "isoCode": "FRO",
    "isoCode2": "FO",
    "id": 76
  },
  {
    "name": "Finland",
    "isoCode": "FIN",
    "isoCode2": "FI",
    "id": 75
  },
  {
    "name": "France",
    "isoCode": "FRA",
    "isoCode2": "FR",
    "id": 27
  },
  {
    "name": "Georgia",
    "isoCode": "GEO",
    "isoCode2": "GE",
    "id": 95
  },
  {
    "name": "Germany",
    "isoCode": "DEU",
    "isoCode2": "DE",
    "id": 35
  },
  {
    "name": "Greece",
    "isoCode": "GRC",
    "isoCode2": "GR",
    "id": 40
  },
  {
    "name": "Greenland",
    "isoCode": "GRL",
    "isoCode2": "GL",
    "id": 97
  },
  {
    "name": "Guatemala",
    "isoCode": "GTM",
    "isoCode2": "GT",
    "id": 61
  },
  {
    "name": "Haiti",
    "isoCode": "HTI",
    "isoCode2": "HT",
    "id": 81
  },
  {
    "name": "Honduras",
    "isoCode": "HND",
    "isoCode2": "HN",
    "id": 62
  },
  {
    "name": "Hong Kong SAR",
    "isoCode": "HKG",
    "isoCode2": "HK",
    "id": 44
  },
  {
    "name": "Hungary",
    "isoCode": "HUN",
    "isoCode2": "HU",
    "id": 88
  },
  {
    "name": "Iceland",
    "isoCode": "ISL",
    "isoCode2": "IS",
    "id": 92
  },
  {
    "name": "India",
    "isoCode": "IND",
    "isoCode2": "IN",
    "id": 20
  },
  {
    "name": "Indonesia",
    "isoCode": "IDN",
    "isoCode2": "ID",
    "id": 90
  },
  {
    "name": "Iran",
    "isoCode": "IRN",
    "isoCode2": "IR",
    "id": 73
  },
  {
    "name": "Iraq",
    "isoCode": "IRQ",
    "isoCode2": "IQ",
    "id": 7
  },
  {
    "name": "Ireland",
    "isoCode": "IRL",
    "isoCode2": "IE",
    "id": 45
  },
  {
    "name": "Israel",
    "isoCode": "ISR",
    "isoCode2": "IL",
    "id": 86
  },
  {
    "name": "Italy",
    "isoCode": "ITA",
    "isoCode2": "IT",
    "id": 93
  },
  {
    "name": "Jamaica",
    "isoCode": "JAM",
    "isoCode2": "JM",
    "id": 46
  },
  {
    "name": "Japan",
    "isoCode": "JPN",
    "isoCode2": "JP",
    "id": 94
  },
  {
    "name": "Jordan",
    "isoCode": "JOR",
    "isoCode2": "JO",
    "id": 8
  },
  {
    "name": "Kazakhstan",
    "isoCode": "KAZ",
    "isoCode2": "KZ",
    "id": 96
  },
  {
    "name": "Kenya",
    "isoCode": "KEN",
    "isoCode2": "KE",
    "id": 127
  },
  {
    "name": "Korea",
    "isoCode": "KOR",
    "isoCode2": "KR",
    "id": 99
  },
  {
    "name": "Kuwait",
    "isoCode": "KWT",
    "isoCode2": "KW",
    "id": 9
  },
  {
    "name": "Kyrgyzstan",
    "isoCode": "KGZ",
    "isoCode2": "KG",
    "id": 100
  },
  {
    "name": "Laos",
    "isoCode": "LAO",
    "isoCode2": "LA",
    "id": 101
  },
  {
    "name": "Latvia",
    "isoCode": "LVA",
    "isoCode2": "LV",
    "id": 103
  },
  {
    "name": "Lebanon",
    "isoCode": "LBN",
    "isoCode2": "LB",
    "id": 10
  },
  {
    "name": "Libya",
    "isoCode": "LBY",
    "isoCode2": "LY",
    "id": 11
  },
  {
    "name": "Liechtenstein",
    "isoCode": "LIE",
    "isoCode2": "LI",
    "id": 36
  },
  {
    "name": "Lithuania",
    "isoCode": "LTU",
    "isoCode2": "LT",
    "id": 102
  },
  {
    "name": "Luxembourg",
    "isoCode": "LUX",
    "isoCode2": "LU",
    "id": 37
  },
  {
    "name": "Malaysia",
    "isoCode": "MYS",
    "isoCode2": "MY",
    "id": 47
  },
  {
    "name": "Maldives",
    "isoCode": "MDV",
    "isoCode2": "MV",
    "id": 38
  },
  {
    "name": "Mali",
    "isoCode": "MLI",
    "isoCode2": "ML",
    "id": 83
  },
  {
    "name": "Malta",
    "isoCode": "MLT",
    "isoCode2": "MT",
    "id": 107
  },
  {
    "name": "Mexico",
    "isoCode": "MEX",
    "isoCode2": "MX",
    "id": 63
  },
  {
    "name": "Moldova",
    "isoCode": "MDA",
    "isoCode2": "MD",
    "id": 116
  },
  {
    "name": "Monaco",
    "isoCode": "MCO",
    "isoCode2": "MC",
    "id": 82
  },
  {
    "name": "Mongolia",
    "isoCode": "MNG",
    "isoCode2": "MN",
    "id": 105
  },
  {
    "name": "Montenegro",
    "isoCode": "MNE",
    "isoCode2": "ME",
    "id": 125
  },
  {
    "name": "Morocco",
    "isoCode": "MAR",
    "isoCode2": "MA",
    "id": 12
  },
  {
    "name": "Myanmar",
    "isoCode": "MMR",
    "isoCode2": "MM",
    "id": 108
  },
  {
    "name": "Nepal",
    "isoCode": "NPL",
    "isoCode2": "NP",
    "id": 110
  },
  {
    "name": "Netherlands",
    "isoCode": "NLD",
    "isoCode2": "NL",
    "id": 85
  },
  {
    "name": "New Zealand",
    "isoCode": "NZL",
    "isoCode2": "NZ",
    "id": 48
  },
  {
    "name": "Nicaragua",
    "isoCode": "NIC",
    "isoCode2": "NI",
    "id": 64
  },
  {
    "name": "Nigeria",
    "isoCode": "NGA",
    "isoCode2": "NG",
    "id": 91
  },
  {
    "name": "North Macedonia",
    "isoCode": "MKD",
    "isoCode2": "MK",
    "id": 104
  },
  {
    "name": "Norway",
    "isoCode": "NOR",
    "isoCode2": "NO",
    "id": 109
  },
  {
    "name": "Oman",
    "isoCode": "OMN",
    "isoCode2": "OM",
    "id": 13
  },
  {
    "name": "Pakistan",
    "isoCode": "PAK",
    "isoCode2": "PK",
    "id": 111
  },
  {
    "name": "Panama",
    "isoCode": "PAN",
    "isoCode2": "PA",
    "id": 65
  },
  {
    "name": "Paraguay",
    "isoCode": "PRY",
    "isoCode2": "PY",
    "id": 68
  },
  {
    "name": "Peru",
    "isoCode": "PER",
    "isoCode2": "PE",
    "id": 66
  },
  {
    "name": "Philippines",
    "isoCode": "PHL",
    "isoCode2": "PH",
    "id": 49
  },
  {
    "name": "Poland",
    "isoCode": "POL",
    "isoCode2": "PL",
    "id": 112
  },
  {
    "name": "Portugal",
    "isoCode": "PRT",
    "isoCode2": "PT",
    "id": 115
  },
  {
    "name": "Puerto Rico",
    "isoCode": "PRI",
    "isoCode2": "PR",
    "id": 67
  },
  {
    "name": "Qatar",
    "isoCode": "QAT",
    "isoCode2": "QA",
    "id": 14
  },
  {
    "name": "Réunion",
    "isoCode": "REU",
    "isoCode2": "RE",
    "id": 84
  },
  {
    "name": "Romania",
    "isoCode": "ROU",
    "isoCode2": "RO",
    "id": 117
  },
  {
    "name": "Russia",
    "isoCode": "RUS",
    "isoCode2": "RU",
    "id": 22
  },
  {
    "name": "Rwanda",
    "isoCode": "RWA",
    "isoCode2": "RW",
    "id": 118
  },
  {
    "name": "Saudi Arabia",
    "isoCode": "SAU",
    "isoCode2": "SA",
    "id": 15
  },
  {
    "name": "Senegal",
    "isoCode": "SEN",
    "isoCode2": "SN",
    "id": 74
  },
  {
    "name": "Serbia",
    "isoCode": "SRB",
    "isoCode2": "RS",
    "id": 126
  },
  {
    "name": "Singapore",
    "isoCode": "SGP",
    "isoCode2": "SG",
    "id": 50
  },
  {
    "name": "Slovakia",
    "isoCode": "SVK",
    "isoCode2": "SK",
    "id": 121
  },
  {
    "name": "Slovenia",
    "isoCode": "SVN",
    "isoCode2": "SI",
    "id": 122
  },
  {
    "name": "Somalia",
    "isoCode": "SOM",
    "isoCode2": "SO",
    "id": 123
  },
  {
    "name": "South Africa",
    "isoCode": "ZAF",
    "isoCode2": "ZA",
    "id": 2
  },
  {
    "name": "Spain",
    "isoCode": "ESP",
    "isoCode2": "ES",
    "id": 29
  },
  {
    "name": "Sri Lanka",
    "isoCode": "LKA",
    "isoCode2": "LK",
    "id": 120
  },
  {
    "name": "Sweden",
    "isoCode": "SWE",
    "isoCode2": "SE",
    "id": 119
  },
  {
    "name": "Switzerland",
    "isoCode": "CHE",
    "isoCode2": "CH",
    "id": 34
  },
  {
    "name": "Syria",
    "isoCode": "SYR",
    "isoCode2": "SY",
    "id": 16
  },
  {
    "name": "Thailand",
    "isoCode": "THA",
    "isoCode2": "TH",
    "id": 128
  },
  {
    "name": "Trinidad & Tobago",
    "isoCode": "TTO",
    "isoCode2": "TT",
    "id": 51
  },
  {
    "name": "Tunisia",
    "isoCode": "TUN",
    "isoCode2": "TN",
    "id": 17
  },
  {
    "name": "Turkey",
    "isoCode": "TUR",
    "isoCode2": "TR",
    "id": 132
  },
  {
    "name": "Turkmenistan",
    "isoCode": "TKM",
    "isoCode2": "TM",
    "id": 130
  },
  {
    "name": "Ukraine",
    "isoCode": "UKR",
    "isoCode2": "UA",
    "id": 133
  },
  {
    "name": "United Arab Emirates",
    "isoCode": "ARE",
    "isoCode2": "AE",
    "id": 3
  },
  {
    "name": "United Kingdom",
    "isoCode": "GBR",
    "isoCode2": "GB",
    "id": 31
  },
  {
    "name": "United States",
    "isoCode": "USA",
    "isoCode2": "US",
    "id": 52
  },
  {
    "name": "Uruguay",
    "isoCode": "URY",
    "isoCode2": "UY",
    "id": 70
  },
  {
    "name": "Uzbekistan",
    "isoCode": "UZB",
    "isoCode2": "UZ",
    "id": 134
  },
  {
    "name": "Venezuela",
    "isoCode": "VEN",
    "isoCode2": "VE",
    "id": 71
  },
  {
    "name": "Vietnam",
    "isoCode": "VNM",
    "isoCode2": "VN",
    "id": 135
  },
  {
    "name": "Yemen",
    "isoCode": "YEM",
    "isoCode2": "YE",
    "id": 18
  },
  {
    "name": "Zimbabwe",
    "isoCode": "ZWE",
    "isoCode2": "ZW",
    "id": 53
  }
];

export const extractAddress = (address) => {
  if (!address) return "";
  return address.substring(0, 4) + "..." + address.substring(address.length - 6);
}

export const countResult = (num) => {
  if (!num) { return false; }
  return Math.pow(10, num - 1);
}