import { SET_TOKEN } from "../../Constants/ReduxKeys";

let initialState = {
  token: localStorage.getItem("token"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TOKEN:
      localStorage.setItem("token", action.payload);
      return {
        ...state,
        token: action.payload,
      };
    default:
      break;
  }
  return state;
};

export default reducer;